<div class="full section-data map-legend-container">
  <div class='table-cell'>
    <ng-container *ngIf="this.step === 'blockmap' else seatmapLegend">
      <div class="legend row">
        <div class="col-md-4">
          <i class="fa fa-circle legend-circle blockmap-legend-circle-available"></i>
          <span class="legend-text">Available</span>
        </div>
        <div class="col-md-4">
          <i class="fa fa-circle legend-circle blockmap-legend-circle-selected"></i>
          <span class="legend-text">Selected</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #seatmapLegend>
  <div class="legend row" *ngIf="this.step === 'seatmap'" [ngClass]="component === 'inventory' ? 'w90': ''">
    <!--michele request En Pryce Type Assignament, en la leyenda inferior del widget con Available y Selectes, el
    campo selected se mueve cuando entras en la visualización de sectores-->
    <div [ngClass]="component === 'inventory' ? 'col-md-2' : 'col-md-4'">
      <i class="fa fa-circle legend-circle seatmap-legend-circle-available"></i>
      <span class="legend-text">Available</span>
    </div>
    <div [ngClass]="component === 'inventory' ? 'col-md-2' : 'col-md-4'">
      <i class="fa fa-circle legend-circle seatmap-legend-circle-selected"></i>
      <span class="legend-text">Selected</span>
    </div>
    <div class="col-md-2" *ngIf="component === 'inventory'">
      <i class="fa fa-circle legend-circle seatmap-legend-circle-locked"></i>
      <span class="legend-text">Locked</span>
    </div>
    <div class="col-md-2" *ngIf="component === 'inventory'">
      <i class="fa fa-circle legend-circle seatmap-legend-circle-owned"></i>
      <span class="legend-text">Owned</span>
    </div>
    <div class="col-md-2" *ngIf="component === 'inventory'">
      <i class="fa fa-circle legend-circle seatmap-legend-circle-no-price-type"></i>
      <span class="legend-text">No Price Type</span>
    </div>
  </div>
</ng-template>
