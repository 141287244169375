import { Component, OnInit } from '@angular/core';
import { DvmService } from '../../shared';
import { FlowControlService } from '../services';
import { ClickHandler, LoadHandler, PopoverDestroyHandler,
  PopoverCreateHandler, SelectionAreaHandler} from '../handlers';


@Component({
  selector: 'app-inventory-landing',
  templateUrl: './inventory-landing.component.html',
  styleUrls: ['./inventory-landing.component.scss'],
  providers: [
    ClickHandler,
    LoadHandler,
    PopoverCreateHandler,
    PopoverDestroyHandler,
    SelectionAreaHandler
  ]
})
export class InventoryLandingComponent implements OnInit {

  constructor(private flowControlService: FlowControlService,
              private clickHandler: ClickHandler,
              private loadHandler: LoadHandler,
              private popoverCreateHandler: PopoverCreateHandler,
              private popoverDestroyHandler: PopoverDestroyHandler,
              private selectionAreaHandler: SelectionAreaHandler,
              private dvmService: DvmService) { }

  ngOnInit(): void {
    this.dvmService.restartDVM();
  }

  get step(): string {
    return this.flowControlService.flowStep;
  }

  get legendsMode(): 'inventory' | 'pricing' {
    return this.flowControlService.colorsmode;
  }
}
