<div class="full flex mb-5">
  <div class="flex full j-center">
    <div class="half flex j-center f-column counter-wrapper noselect">
      <div class="counter-number">
        <h4>{{onlineUsers}}</h4>
      </div>
      <div class="counter-text">
        Active users on site
      </div>
    </div>
  </div>
</div>
<div class="full flex mb-5">
  <div class="f-row full flex j-center">
    <div class="half flex j-center metrics-wrap f-column">
      <div class="metrics-title full">
        <h4>{{this.userBar.relocated_users}}&nbsp;/&nbsp;{{this.userBar.total_users}} Users relocated</h4>
      </div>
      <div class="metrics-container">
        <div class="metrics-content">
          <progressbar [animate]="true" [value]='this.userBar.porcentaje' type="success">
            <b>{{this.userBar.porcentaje}}%</b></progressbar>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="full flex f-row spe noselect ">
  <div class="w40 flex j-center metrics-wrap f-column">
    <div class="metrics-title full">
      <h4>Currently logged in</h4>
    </div>
    <div class="metrics-container" style="height: 450px; overflow-y: scroll;">
      <div class="metrics-content">
        <table class="full mgb1">
          <thead class="customTable">
          <tr class="full t-row spb line noselect">
            <th class="w10">Account #</th>
            <th class="w10">Full Name</th>
          </tr>
          </thead>
          <tbody class="full f-column spb">
          <tr class="full t-row spb line hov" *ngFor="let user of tableUsers">
            <td>{{user.username | titlecase}}</td>
            <td *ngIf="user.account_name else account_name_notfound">{{user.account_name}}</td>
            <ng-template #account_name_notfound>
              <td > - </td>
            </ng-template>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="loader" [style.opacity]="hideLoading ? '0' : '1'" [style.visibility]="hideLoading ? 'hidden' : 'visible'">
        <div class="spinner-border loading-color " role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <!--<div class="metrics-container" style="height: 450px">
      <div class="metrics-content">
        <app-horizontal-bar-chart *ngIf="userConversionData" [data]="horizontalChartBaseConfig"></app-horizontal-bar-chart>
      </div>
    </div> -->
  </div>
  <div class="w40 flex j-center metrics-wrap f-column">
    <div class="metrics-title full">
      <h4>Total User Logged in</h4>
    </div>
    <div class="metrics-container" style="height: 450px">
      <div class="metrics-content">
        <app-pie-chart style="width: 100%;" *ngIf="userLoggedInData" [data]="userLoggedInConfig"></app-pie-chart>
      </div>
    </div>
  </div>
</div>
