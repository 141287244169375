import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DvmStylesService {

  hashStyles: any = {
    locked: {
      icon: 'icon-cross',
      iconScale: 0.7,
      iconFillStyle: 'red',
      strokeStyle: 'white',
      fillOpacity: 0.7,
      lineWidth: 0.1,
      cursor: 'pointer'
    },
    available: {
      fillStyle: '#1D8EF9',
      strokeStyle: 'white',
      fillOpacity: 0.7,
      lineWidth: 0.1,
      cursor: 'pointer'
    },
    on_hold: {
      fillStyle: 'yellow',
      strokeStyle: 'white',
      fillOpacity: 0.7,
      lineWidth: 0.1,
      cursor: 'pointer'
    },
    owned: {
      fillStyle: 'orange',
      strokeStyle: 'white',
      fillOpacity: 0.7,
      lineWidth: 0.1,
      cursor: 'pointer'
    },
    nopricetype: {
      fillStyle: 'pink',
      strokeStyle: 'white',
      fillOpacity: 0.7,
      lineWidth: 0.1,
      cursor: 'pointer'
    },
    reserved: {
      fillStyle: 'orangered !important',
      strokeStyle: 'white',
      fillOpacity: 0.7,
      lineWidth: 0.1,
      cursor: 'pointer'
    },
    customerView: {
      cursor: 'default'
    }
  };

  constructor() { }

  setSeatHash(seats: Array<any>): object {
    const hashSeat = {};
    for (const seat of seats) {
      for (const gName of seat.groups) {
        hashSeat[seat.id] = Object.assign({}, hashSeat[seat.id], this.hashStyles[gName]);
      }
    }

    return hashSeat;
  }

}
