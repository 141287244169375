import { Component, Input, OnInit } from '@angular/core';
import { HorizontalBarModel } from './horizontal-bar.model';
import { ChartConfigService } from '../chart-config.service';

@Component({
  selector: 'app-horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html'
})
export class HorizontalBarChartComponent implements OnInit {

  @Input() data;
  chartBaseConfig: HorizontalBarModel;
  constructor(private chartConfigService: ChartConfigService) {}

  ngOnInit(): void {
    this.chartBaseConfig = this.chartConfigService.setHorizontalBarChartConfig(this.data);
  }

}
