<div id="sidenav-wrapper">
  <div class='menu-wrap' (click)="toggleMenu()">
    <div class="menu-box">
      <div class="b1"></div>
      <div class="b2"></div>
      <div class="b3"></div>
    </div>
  </div>
  <!-- SIDE MENU -->
  <div class="sidenav">
    <a routerLink="/home" routerLinkActive="active" class="sidenav-item">
      <div class="sidenav-item-ico">
        <span class="ti-home"></span>
      </div>
      <div class="sidenav-item-large">
        <div>Home</div>
      </div>
    </a>
    <a *ngIf="!appConfig.tdc" routerLink="/inventory" routerLinkActive="active" class="sidenav-item">
      <div class="sidenav-item-ico">
        <span class="ti-map-alt"></span>
      </div>
      <div class="sidenav-item-large">
        <div>Inventory</div>
      </div>
    </a>
    <a routerLinkActive="active" *ngIf="!appConfig.tdc" class="sidenav-item c-drop pointer">
      <div class="sidenav-item-ico">
        <span class="ti-money"></span>
      </div>
      <div class="sidenav-item-large">
        <div>Pricing</div>
      </div>
      <div class="sidenav-item-dropdown">
        <a routerLink="/price/price-management" routerLinkActive="active" class="sidenav-item">
          <div class="sidenav-item-ico">
            <span class="ti-map"></span>
          </div>
          <div class="sidenav-item-large-drop">
            <div>Price Type Management </div>
          </div>
        </a>
        <hr class="menu-dropdown-line">
        <a routerLink="/price/price-assign" routerLinkActive="active" class="sidenav-item">
          <div class="sidenav-item-ico">
            <span class="ti-map-alt"></span>
          </div>
          <div class="sidenav-item-large-drop">
            <div>Price Type Assignament</div>
          </div>
        </a>
      </div>
    </a>
<!--    <a routerLink="/metrics" routerLinkActive="active" class="sidenav-item">-->
<!--      <div class="sidenav-item-ico">-->
<!--        <span class="ti-bar-chart"></span>-->
<!--      </div>-->
<!--      <div class="sidenav-item-large">-->
<!--        <div>Metrics</div>-->
<!--      </div>-->
<!--    </a>-->
    <a routerLink="/transactions/list" routerLinkActive="active" class="sidenav-item">
      <div class="sidenav-item-ico">
        <span class="ti-credit-card"></span>
      </div>
      <div class="sidenav-item-large">
        <div>Transactions</div>
      </div>
    </a>
    <a routerLinkActive="active" class="sidenav-item c-drop pointer">
      <div class="sidenav-item-ico">
        <span class="ti-agenda"></span>
      </div>
      <div class="sidenav-item-large">
        <div>Users</div>
      </div>
      <div class="sidenav-item-dropdown">
        <a routerLink="/users/customers" routerLinkActive="active" class="sidenav-item">
          <div class="sidenav-item-ico">
            <span class="ti-id-badge"></span>
          </div>
          <div class="sidenav-item-large-drop">
            <div>Customers</div>
          </div>
        </a>
        <hr class="menu-dropdown-line">
        <a routerLink="/users/sales-rep" routerLinkActive="active" class="sidenav-item">
          <div class="sidenav-item-ico">
            <span class="ti-id-badge"></span>
          </div>
          <div class="sidenav-item-large-drop">
            <div>Sales Rep.</div>
          </div>
        </a>
      </div>
    </a>
  </div>
</div>

