<header class="header">
  <div class="row justify-content-between align-items-center">
    <div class="col-4 text-left">
      <img src="{{appConfig.topbar.logoUrl}}" alt="{{appConfig.team}} Logo" width="35px" style="margin-right: 10px">
      <span id='maintitle'>{{appConfig.team  | titlecase}}</span>
    </div>
    <div class='text-center col-4 text-center'>
    </div>
    <div class='col-4 text-right'>
<!--      TODO  *ngIf="!router.url.includes('login') && user$"-->
      <div id='profile' #profile *ngIf="!router.url.includes('login')">
        <span class='name'>{{user$?.first_name}} {{user$?.last_name}}</span>
        <div id='profile-ico' class='icon-radius' (click)="toggleProfileOpt()"><span class="ti-user"></span></div>
        <div id='profileopt' #profileOpt >
          <div class='profileopt-content'>
            <span id='profileopt-date'>{{this.today | date:'fullDate'}}</span>
            <span class='text-right'>Role: {{user$?.type | titlecase}}</span>
          </div>
          <div class='profileopt-top'>
            <button type='button' class="btn-link" (click)="logout()">Log Out</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
