import { Component, Inject, OnInit } from '@angular/core';
import { DVM_CONFIG } from '../../../configuration/dvm.configuration';
import { DVMConfiguration } from '../../../configuration/dvm-configuration.model';
import { DvmService } from '../../service';
import { FlowControlService } from '../../../inventory-management/services';

@Component({
  selector: 'app-map-topbar',
  templateUrl: './map-topbar.component.html',
  styleUrls: ['./map-topbar.component.scss']
})
export class MapTopbarComponent implements OnInit {

  constructor(@Inject(DVM_CONFIG) public dvmConfig: DVMConfiguration,
              private dvmService: DvmService,
              private flowService: FlowControlService) { }

  ngOnInit(): void {
  }

  get currentSection(): string {
    if (this.dvmService.viewer) {
      return this.dvmService.viewer.getMapId().split('-')[0].split('_')[1];
    }
  }

  get isStep2(): boolean | string {
    if (this.dvmService.viewer && this.dvmService.viewer.getMapId()) {
      return this.dvmService.viewer.getMapId() !== this.dvmConfig.map_id;
    }
    return false;
  }

  goBack(): void {
    this.flowService.sectionSelected = null;
    this.flowService.setSectionData(null);
    const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
    loadOptions.venue_id = this.dvmService.viewer.getVenueId();
    this.dvmService.loadMap(loadOptions);
  }

}
