<div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0">
  <ngx-charts-bar-horizontal
    [view]=""
    [legendPosition]="chartBaseConfig.legendPosition"
    [scheme]="chartBaseConfig.scheme"
    [results]="chartBaseConfig.results"
    [gradient]="chartBaseConfig.gradient"
    [xAxis]="chartBaseConfig.xAxis"
    [yAxis]="chartBaseConfig.yAxis"
    [legend]="chartBaseConfig.legend"
    [showXAxisLabel]="chartBaseConfig.showXAxisLabel"
    [showYAxisLabel]="chartBaseConfig.showXAxisLabel"
    [xAxisLabel]="chartBaseConfig.xAxisLabel"
    [yAxisLabel]="chartBaseConfig.yAxisLabel">
  </ngx-charts-bar-horizontal>
</div>

