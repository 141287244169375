import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-legend',
  templateUrl: './map-legend.component.html',
  styleUrls: ['./map-legend.component.scss']
})
export class MapLegendComponent implements OnInit {

  @Input() step: string;
  @Input() component: 'inventory' | 'pricing';

  constructor() { }

  ngOnInit(): void {
  }

}
