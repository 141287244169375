<!-- <div class="flex"> -->
<div class="full" id="side-interface">
  <tabset>
    <tab heading="Inventory View" id="tab1" (selectTab)="onChangePlanSelectorInventory(1)">
      <ng-template [ngTemplateOutlet]="inventoryview"></ng-template>
    </tab>
    <tab heading="Customer View" id="tab2" (selectTab)="onChangePlanSelectorCustomer(1)">
      <ng-template [ngTemplateOutlet]="customerview"></ng-template>
    </tab>
  </tabset>
</div>

<ng-template #buttonSave>
  <div class="mgt1 j-end full flex">
    <div *ngIf="this.errorOnSave">
      <p>The selected item is invalid</p>
    </div>
    <div>
      <button class="button uxColor" (click)="onSave()">Save</button>
    </div>
  </div>
</ng-template>


<div class="full mgt1" *ngIf="!flowservice.sectionSelected; else sectionExport">
  <div class="full flex j-end">
    <button (click)="onExport()" class="button uxColor">Export CSV</button>
  </div>
</div>

<ng-template #sectionExport>
  <div class="full mgt1">
    <div class="full flex j-end">
      <button (click)="onExportSection()" class="button uxColor">Export CSV</button>
    </div>
  </div>
</ng-template>
<!-- </div> -->

<!-- NG TEMPLATE INVENTORY VIEW-->
<ng-template #inventoryview>
  <label class="weight noselect">Plan</label>
  <div [ngClass]="{'flex f-row' : this.flowservice.flowStep === 'seatmap'}">
    <select class="full input" [(ngModel)]="planSelected" (ngModelChange)="onChangePlanSelectorInventory($event)">
      <option *ngFor="let plan of planList" value="{{plan.id}}">{{plan.name | titlecase}}</option>
    </select>
    <button *ngIf="sectionData && this.flowservice.flowStep === 'seatmap'" (click)="goBack()"
            class="button uxColor mgl1">Back</button>
  </div>
  <!-- Block Map INFO -->
  <div class="mgt1" *ngIf="sectionData && this.flowservice.flowStep === 'blockmap'">
    <p>
      <span class="weight noselect">Section Name: </span> <span class="margin-value">
        {{getseatSection(sectionData?.name)}}</span>
    </p>
    <p>
      <span class="weight noselect">Min Price: </span> <span class="margin-value"> $
      {{formatPrice(sectionData?.min_price)}}</span>
    </p>
    <p>
      <span class="weight noselect">Max Price: </span><span class="margin-value"> $
      {{formatPrice(sectionData?.max_price)}}</span>
    </p>
    <div class="flex full mgt1">
      <div *ngIf="this.flowservice.isGa === false" class="mgt1 full flex j-end">
        <button class="button uxColor" (click)="onEnterSection()">Enter Section</button>
      </div>
    </div>
  </div>
  <!-- Seat Map INFO-->
  <div *ngIf="getSeatSelected > 1 else singleSeat">
    <p class="mgt1">{{getSeatSelected}} seats selected.</p>
    <div class="full mgt1">
      <label>Status
        <select class="full input" [(ngModel)]="this.action">
          <option [value]="'unlock'">Unlocked</option>
          <option [value]="'lock'">Locked</option>
        </select>
      </label>
    </div>
    <ng-template [ngTemplateOutlet]="buttonSave"></ng-template>
  </div>


</ng-template>

<!-- NG TEMPLATE CUSTOMER VIEW -->
<ng-template #customerview>
  <label class="weight noselect">Plan</label>
  <div [ngClass]="{'flex f-row' : this.flowservice.flowStep === 'seatmap'}">
    <select class="full input" [(ngModel)]="planSelected" (ngModelChange)="onChangePlanSelectorCustomer($event)">
      <option *ngFor="let plan of planList" value="{{plan.id}}">{{plan.name | titlecase}}</option>
    </select>
    <button *ngIf="sectionData && this.flowservice.flowStep === 'seatmap'" (click)="goBack()"
            class="button uxColor mgl1">Back</button>
  </div>

  <!-- Block Map INFO -->
  <div class="mgt1" *ngIf="sectionData && this.flowservice.flowStep === 'blockmap'">
    <p>
      <span class="weight noselect">Section Name: </span> <span class="margin-value">
        {{getseatSection(sectionData?.name)}}</span>
    </p>
    <p>
      <span class="weight noselect">Min Price: </span> <span class="margin-value"> $ {{sectionData?.min_price | number}}</span>
    </p>
    <p>
      <span class="weight noselect">Max Price: </span><span class="margin-value"> $ {{sectionData?.max_price | number}}</span>
    </p>
    <!-- <div class="mgt1">
            <label>Availability</label>
          <div class="mgt1">
            <label class="noselect">Availability</label>
            <select class="full input" [(ngModel)]="planSelected" (ngModelChange)="onChangePlanSelectorInventory($event)">
              <option *ngFor="let plan of planList.results" value="{{plan.id}}">{{plan.name | titlecase}}</option>
            </select>
          </div> -->
    <div class="flex full mgt1">
      <!-- <div class="mgt1 mgr1">
              <button class="button uxColor">Save</button>
            </div> -->
      <div *ngIf="this.flowservice.isGa === false" class="mgt1 full flex j-end">
        <button class="button uxColor" (click)="onEnterSection()">Enter Section</button>
      </div>
    </div>
  </div>
  <!-- Seat Map INFO-->
  <div *ngIf="getSeatSelected > 1 else singleCustomViewSeat">
    <p class="mgt1">{{getSeatSelected}} seats selected.</p>
  </div>
</ng-template>

<!-- NG TEMPLATE SINGLE SEAT INVENTORY -->
<ng-template #singleSeat>
  <div class="mgt1 flex full f-column alignItemStart" *ngIf="seatData && this.flowservice.flowStep === 'seatmap'">
    <div class="flex f-column full alignItemStart">
      <div class="flex full">
        <span class="margin-label"><span
          class="weight noselect margin-label">Section:</span>{{getseatSection(seatData?.id)}}</span>
        <span class="margin-label"><span
          class="margin-label weight noselect">Row:</span>{{getseatRow(seatData?.id)}}</span>
        <span class="margin-label"><span class="margin-label weight noselect">Seat:</span>
          {{getseatSeat(seatData?.id)}}</span>
      </div>
      <div class="flex full mgt1" *ngIf="this.seatData.price_type else noPT">
        <span class="weight noselect">Price: </span><span
        *ngIf="this.seatData.price_type.prices?.length > 0 else noPrice" class="margin-value"> $
        {{this.seatData.price_type.prices[0]?.price}}</span>
        <ng-template #noPrice>
          <span class="margin-value">Don't have price</span>
        </ng-template>
      </div>
      <ng-template #noPT>
        <div class="flex full mgt1">
          <span class="weight noselect">Price Type: </span><span class="margin-value">Don't have</span>
        </div>
      </ng-template>
      <div class="flex full mgt1">
        <span class="weight noselect">Seat status: </span><span
        class="margin-value">{{this.seatStatus | titlecase}}</span>
      </div>
      <div class="flex full mgt1">
        <span class="weight noselect">Is Locked: </span>
        <span class="margin-value">{{this.seatData.locked}}</span>
      </div>
    </div>
    <div class="flex full mgt1" *ngIf="seatStatus !== 'available' && seatData.locked !== true">
      <div class=""
           *ngIf="this.seatData.final_event_status && this.seatData.final_event_status.length === 1 else multiCustomer">
        <p>
          <span class="margin-label weight noselect">Account#: </span>
          {{this.seatData.final_event_status[0].customer.username}}
        </p>
        <p><span class="margin-label weight noselect">Account Name:
          </span>{{this.seatData.final_event_status[0].customer.first_name}} {{this.seatData.final_event_status[0].customer.last_name}}
        </p>
        <p>
          <span class="margin-label weight noselect">Plan: </span>
          {{this.seatData.final_event_status[0].plan.name | titlecase}}
        </p>
      </div>
    </div>
    <div class="flex full mgt1" *ngIf="seatStatus === 'owned' && seatData.locked === true">
      <div class=""
           *ngIf="this.seatData.final_event_status && this.seatData.final_event_status.length === 1 else multiCustomer">
        <p>
          <span class="margin-label weight noselect">Account#: </span>
          {{this.seatData.final_event_status[0].customer.username}}
        </p>
        <p><span class="margin-label weight noselect">Account Name:
          </span>{{this.seatData.final_event_status[0].customer.account_name}}
        </p>
        <p>
          <span class="margin-label weight noselect">Plan: </span>
          {{this.seatData.final_event_status[0].plan.name | titlecase}}
        </p>
      </div>
    </div>
    <div class="full mgt1">
      <label class="weight noselect">Status
        <select class="full input" [(ngModel)]="this.action">
          <option value="unlock">Unlock</option>
          <option value="lock">Lock</option>
        </select>
      </label>
    </div>
    <ng-template [ngTemplateOutlet]="buttonSave"></ng-template>
  </div>
</ng-template>

<!-- NG TEMPLATE SINGLE CUSTOMER VIEW SEAT -->
<ng-template #singleCustomViewSeat>
  <div class="mgt1 flex full f-column alignItemStart" *ngIf="seatData && this.flowservice.flowStep === 'seatmap'">
    <div class="flex f-column full alignItemStart">
      <div class="flex full">
        <span class="margin-label"><span
          class="weight noselect margin-label">Section:</span>{{getseatSection(seatData?.id)}}</span>
        <span class="margin-label"><span
          class="margin-label weight noselect">Row:</span>{{getseatRow(seatData?.id)}}</span>
        <span class="margin-label"><span class="margin-label weight noselect">Seat:</span>
          {{getseatSeat(seatData?.id)}}</span>
      </div>
      <div class="flex full mgt1" *ngIf="this.seatData.price_type else noPT">
        <span class="weight noselect">Price: </span><span
        *ngIf="this.seatData.price_type.prices?.length > 0 else noPrice" class="margin-value"> $
        {{this.seatData.price_type.prices[0]?.price}}</span>
        <ng-template #noPrice>
          <span class="margin-value">Don't have price</span>
        </ng-template>
      </div>
      <ng-template #noPT>
        <div class="flex full mgt1">
          <span class="weight noselect">Price Type: </span><span class="margin-value">Don't have</span>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #multiCustomer>
  <div class="">
    <p><span>This seat has multiple Holders</span></p>
    <p *ngFor="let element of this.seatData.final_event_status">
      <span class="margin-label weight noselect">Licensee:</span>
      {{this.seatData.final_event_status[0].customer.account_name}}<br>
      <span class="margin-label weight noselect">Plan: </span>
      {{element.plan.name | titlecase}}
    </p>
  </div>
</ng-template>

<div class="text-export-inventory">
  <p>If you desire to export the availability of the whole venue, click on <b>Export CSV</b> without entering on a section.</p>
</div>

