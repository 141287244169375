import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG } from '../../configuration/backoffice-configuration';
import { BackofficeConfigurationModel } from '../../configuration/backoffice-configuration.model';
import { DjangoSessionAuthenticationService } from '../../auth';
import { Router } from '@angular/router';
import { BackofficeUser } from '../../shared/models';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  open: boolean;
  user: BackofficeUser | boolean;

  constructor(@Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel,
              private sessionService: DjangoSessionAuthenticationService,
              public router: Router) {
    this.open = true;
  }

  ngOnInit(): void {
    this.sessionService.getUserLogged$()
      .subscribe((response) => {
        this.user = response;
      });
  }

  toggleMenu(): void {
    document.getElementById('sidenav-wrapper').classList.toggle('open');
    this.open = !this.open;
  }
}
