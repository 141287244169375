export class BackofficeConfigurationModel {
  id: string;
  team: string;
  tdc: boolean;
  topbar: {
    logoUrl: string,
  };
  colors: {
    main: string,
    accent: string,
    header: string,
  };
  generalAdmissionAreas: Array<string>;
  rules: {};
  dvmConfig: {
    venue_id: string,
    map_id: string,
    version: string
  };
  fields: {
    'account-name'?: boolean;
  };
}
