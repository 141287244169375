import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { PlanModel } from '../../shared/models';
import { DvmService } from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  seatsSelectedLengh = new Subject<number>();
  errorOnSaveSubject = new Subject<boolean>();

  constructor(private http: HttpClient, private dvmService: DvmService) { }

  getPlanInventory(planId: number): Observable<any> { // TODO Create Model
    return this.http.get<any>(`/plan/${planId}/inventory/`);
  }

  getSectionInventory(planId: number, sectionId: string): Observable<any> { // TODO Create Model
    return this.http.get<any>(`/plan/${planId}/inventory/${sectionId}`);
  }

  getPlans(): Observable<Array<PlanModel>> {
    return this.http.get<Array<PlanModel>>(`/get_plans_readonly/`);
  }

  lockSeat(seatList: Array<any>, planId: number): Observable<any> {
    return this.http.post(`/plan/${planId}/seats/lock/`, {seats: seatList});
  }

  unlockSeats(seatList: Array<any>, planId: number): Observable<any> {
    return this.http.post(`/plan/${planId}/seats/unlock/`, {seats: seatList});
  }

  getSeatSelectedLength(): Observable<number> {
    return this.seatsSelectedLengh.asObservable();
  }

  setSeatSelectedLength(length: number): void {
    this.seatsSelectedLengh.next(length);
  }

  getPlanAvailability(plan: number): Observable<any> {
    return this.http.get<any>(`/plan/${plan}/availability/`);
  }

  getPlanAvailabilitySection(plan: number, section: string): Observable<any> {
    return this.http.get(`/plan/${plan}/availability/${section}/`);
  }

  setSeatsGroup(filteredAvailability: any): void {
    // Apply filters
    const arrayStatus = ['locked', 'on_hold', 'reserved', 'owned', 'nopricetype'];
    this.removeSeatsGroups();
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.available), 'available');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.locked), 'locked');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.on_hold), 'on_hold');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.reserved), 'reserved');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.owned), 'owned');
    this.dvmService.viewer.addNodesToGroup(Object.keys(filteredAvailability.nopricetype), 'nopricetype');
  }

  removeSeatsGroups(): void {
    const arrayStatus = ['locked', 'on_hold', 'reserved', 'owned', 'nopricetype'];
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'locked'),
      'locked'
    );
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'on_hold'),
      'on_hold'
    );
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'reserved'),
      'reserved'
    );
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'owned'),
      'owned'
    );
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'nopricetype'),
      'nopricetype'
    );
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'customerView'),
      'customerView'
    );
  }

  // Se filtra por estado del asiento y se crea un objeto los diferentes status
  createAvailabilityHashmap(seatmaparray: any): any {
    const hashmap = {available: {}, on_hold: {}, reserved: {}, locked: {}, owned: {}, nopricetype: {}};
    for (const obj of seatmaparray) {
      // Available Filter
      if (obj.locked === true) {
        hashmap.locked[obj.id] = obj;
      }
      if (!obj.price_type || obj.price_type.prices.length === 0) {
        hashmap.nopricetype[obj.id] = obj;
        continue;
      }
      if (obj.final_event_status.length === 0) {
        hashmap.available[obj.id] = obj;
        continue;
      }
      const seatStatus = obj.final_event_status[0].status;
      switch (seatStatus) {
        case 'on_hold': {
          hashmap.on_hold[obj.id] = obj;
          break;
        }
        case 'owned': {
          hashmap.owned[obj.id] = obj;
          break;
        }
        case 'reserved': {
          hashmap.reserved[obj.id] = obj;
          break;
        }
      }
    }
    return hashmap;
  }

  // Se prepara un hashmap para insertar la disponibilidad
  getSeatmapAvailability(seatmaparray: any): any {
    const availability = {};
    for (const obj of seatmaparray) {
      availability[obj.id] = obj;
    }
    return availability;
  }

  getPlanCSV(planID: number): Observable<any> {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: 'text/csv'
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get(`/plan/${planID}/export`, HTTPOptions);
  }

  getPlanFromSectionCSV(planId: number, section: string): Observable<any> {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: 'text/csv'
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get(`/plan/${planId}/export/${section}`, HTTPOptions);
  }
}
