<div class="modal-header white j-center">
  <h4 class="mg0 noselect title-info">{{title}}</h4>
  <button type="button" class="m-close" (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="white ti-close"></span>
  </button>
</div>
<div class="modal-body aCenter">
  <span class="alert-text">{{content}}</span>
</div>
<div class="modal-footer j-center">
  <button *ngIf="closeBtnName" type="button" class="btn btn-modal-back" (click)="close()">{{closeBtnName}}</button>
  <button  type="button" class="btn btn-modal-forward ip4button button_centered button uxColor white" (click)="accept()">{{acceptBtnName}}</button>
</div>
