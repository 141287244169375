<h2 class="start title mgb1">Inventory Management</h2>

<div class="wrap-interface">
  <div class="flex f-row j-center ">
    <div class="flex f-column w20 start">
      <app-side-interface class="full" style="margin: 20px 22px 20px 20px;"></app-side-interface>
    </div>
    <div class="w70">
      <div id="map-interface-card">
        <app-map-topbar></app-map-topbar>
        <app-map-view></app-map-view>
        <app-map-legend [component]="legendsMode" [step]="step"></app-map-legend>
      </div>
    </div>
  </div>
</div>
