import { Injectable, Inject } from '@angular/core';
import { GeneralHandler } from './general.handler';
import { DvmService } from '../../shared';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';

@Injectable()
export class PopoverDestroyHandler extends GeneralHandler {
    constructor(protected dvmService: DvmService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
        super('leave', dvmService);
    }

    protected handle(obj) {
        // Check if node is a seat
        if (obj.nodes[0].type === 'seat') {
            if (obj.nodes[0].state !== 'selected') {
                this.oldStyle(obj.nodes);
            }
        }

        const tooltip: HTMLElement = document.querySelector('#tooltip');
        tooltip.removeAttribute('data-show');
    }

    // Removes the style of the node and sets the style of it group
    private oldStyle(nodes) {
        const seatStyles = this.dvmService.setStyles(nodes);
        for (const seat of Object.keys(seatStyles)) {
            (this.dvmService.viewer as any).setNodeCustomStyles(seat, [seatStyles[seat]]);
        }
    }
}
