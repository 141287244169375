import { Component, Input, OnInit } from '@angular/core';
import { ChartConfigService } from '../chart-config.service';
import { VerticalBarChartModel } from './vertical-bar-chart.model';

@Component({
  selector: 'app-vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
  styleUrls: ['./vertical-bar-chart.component.scss']
})
export class VerticalBarChartComponent implements OnInit {

  @Input() data;
  chartBaseConfig: VerticalBarChartModel;

  constructor(private chartConfigService: ChartConfigService) { }

  ngOnInit(): void {
    this.chartBaseConfig = this.chartConfigService.setVerticalBarChartConfig(this.data);
  }


}
