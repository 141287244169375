import { Injectable, Inject } from '@angular/core';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { FlowControlService, InventoryService } from '../services';
import { DvmService } from '../../shared';

@Injectable()
export class LoadHandler extends GeneralHandler {
    constructor(@Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
                private flowControl: FlowControlService,
                private inventoryService: InventoryService,
                protected dvmService: DvmService) {
        super('end_load', dvmService);
    }

    protected handle(error, viewer): void {
        if (error) { console.log(error); }
        viewer = viewer.instance;
        console.log('Load Handler:', viewer);
        this.flowControl.seatHash = {};
        this.inventoryService.seatsSelectedLengh.next(Object.keys(this.flowControl.seatHash).length);
        if (viewer.getMapId() === this.dvmConfig.map_id) {
            this.loadBlockmapHandler(viewer);
        } else {
            this.loadSeatmapHandler(viewer);
        }
    }

    loadBlockmapHandler(viewer): void {
        this.dvmService.viewer.selection_area.mouse_options.ctrlKey = false;
        this.flowControl.flowStep = 'blockmap';
        viewer.setAvailability([]);
        this.inventoryService.getPlanInventory(1).subscribe(
            planAvailability => {
                this.flowControl.availabilityBlockmap = planAvailability;
                // viewer.setAvailable('section', this.flowControl.availabilityBlockmap);
                viewer.setAvailable('section', Object.keys(planAvailability));
            },
            error => {
                console.error(error);
            }
        );
    }

    loadSeatmapHandler(viewer): void {
        this.dvmService.viewer.selection_area.mouse_options.ctrlKey = true;
        this.flowControl.flowStep = 'seatmap';
        viewer.setAvailable([]);
        if (this.flowControl.viewerMode === 'inventory') {
            this.inventoryService.getSectionInventory(this.flowControl.planSelected, viewer.getMapId()).subscribe(
                availability => {
                    this.flowControl.availabilitySeatmap = this.inventoryService.getSeatmapAvailability(availability);
                    const filteredAvailability = this.inventoryService.createAvailabilityHashmap(availability);
                    // tslint:disable-next-line: max-line-length
                    viewer.setAvailable('seat', Object.keys(filteredAvailability.available).concat(Object.keys(filteredAvailability.locked)).concat(Object.keys(filteredAvailability.owned)));
                    this.inventoryService.setSeatsGroup(filteredAvailability);
                    const seatStyles = this.dvmService.setStyles(viewer.getNodesByType('seat'));
                    for (const seat of Object.keys(seatStyles)) {
                        viewer.setNodeCustomStyles(seat, [seatStyles[seat]]);
                    }
                }
            );
        } else if (this.flowControl.viewerMode === 'customer') {
            viewer.addNodesToGroup(viewer.getNodesByState('seat', 'unavailable'), 'customerView');
            this.inventoryService.removeSeatsGroups();
            this.inventoryService.getPlanAvailabilitySection(this.flowControl.planSelected, viewer.getMapId()).subscribe(
                availability => {
                    this.flowControl.availabilitySeatmap = availability;
                    viewer.setAvailable('seat', Object.keys(availability));
                    const seatStyles = this.dvmService.setStyles(viewer.getNodesByType('seat'));
                    for (const seat of Object.keys(seatStyles)) {
                        viewer.setNodeCustomStyles(seat, [seatStyles[seat]]);
                    }
                }
            );
        }

    }

}
