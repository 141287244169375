import { InjectionToken } from '@angular/core';
import { DVMConfiguration } from './dvm-configuration.model';

export const DVM_CONFIG = new InjectionToken<DVMConfiguration>('DVM Configuration');
export const DVMConfig: DVMConfiguration = {
  venue_id: 'nam-us-00096-chicagocubs',
  map_id: 'blockmap',
  container: 'viewer-container',
  version: 'development',
  plugins: ['selection_area']
};
