import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DjangoSessionAuthenticationService } from './auth';
import { MessagingService, ModalComponent } from './shared/info-modal';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AddApiUrlInterceptor, AuthInterceptor,
  HttpXsrfInterceptorMod, WithCredentialsInterceptor } from './shared/interceptors';
import { AuthModalService } from './shared/auth-modal/auth-modal.service';
import { ConfigurationService } from './configuration/configuration.service';
import { APP_CONFIG, ApplicationConfiguration } from './configuration/backoffice-configuration';
import { HeaderComponent, MainLayoutComponent, SideNavComponent } from './layout';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ChartsManagementModule } from './charts-management/charts-management.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { MetricsComponent } from './metrics/metrics.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InventoryManagementModule } from './inventory-management/inventory-management.module';
import { DVM_CONFIG, DVMConfig } from './configuration/dvm.configuration';
import { DVMConfiguration } from './configuration/dvm-configuration.model';

@NgModule({
  declarations: [
    AppComponent,
    ModalComponent,
    HeaderComponent,
    MainLayoutComponent,
    SideNavComponent,
    HomePageComponent,
    LoginPageComponent,
    MetricsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    InventoryManagementModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    LoadingBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ProgressbarModule.forRoot(),
    ChartsManagementModule,
  ],
  providers: [
    DjangoSessionAuthenticationService,
    AuthModalService,
    MessagingService,
    {provide: APP_CONFIG, useValue: ApplicationConfiguration},
    {provide: DVM_CONFIG, useValue: DVMConfig},
    {provide: HTTP_INTERCEPTORS, useClass: AddApiUrlInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptorMod, multi: true},
    {
      provide: APP_INITIALIZER, useFactory: (configService: ConfigurationService) =>
        () => configService.init(), deps: [ConfigurationService], multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
