<div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0">
  <!-- [view]="chartBaseConfig.view" -->
  <ngx-charts-pie-chart
    [view]=""
    [scheme]="chartBaseConfig.scheme"
    [results]="chartBaseConfig.results"
    [gradient]="chartBaseConfig.gradient"
    [legend]="chartBaseConfig.legend"
    [legendPosition]="chartBaseConfig.legendPosition"
    [legendTitle]="chartBaseConfig.legendTitle"
    [labels]="chartBaseConfig.labels"
    [doughnut]="chartBaseConfig.doughnut"
  >
  </ngx-charts-pie-chart>
</div>
