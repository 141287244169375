import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './layout';
import { AuthGuard } from './auth';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { MetricsComponent } from './metrics/metrics.component';
import { InventoryLandingComponent } from './inventory-management/inventory-landing/inventory-landing.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'home',
        component: HomePageComponent
      },
      {
        path: 'users',
        loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'transactions',
        loadChildren: () => import('./transaction-management/transaction-management.module').then(m => m.TransactionManagementModule)
      },
      // {
      //   path: 'metrics',
      //   component: MetricsComponent
      // }
      {
        path: 'inventory',
        component: InventoryLandingComponent
      },
      {
        path: 'price',
        loadChildren: () => import('./pricing-management/pricing-management.module').then(m => m.PricingManagementModule)
      }
    ]
  },
  {
    path: 'login',
    component: LoginPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
