import { Component, OnInit } from '@angular/core';
import { MetricService } from '../charts-management/metric.service';
import { ChartsService } from '../charts-management/charts.service';
import { PieChartModel } from '../charts-management/pie-chart';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit {

  addonsPerPriceType;
  addonsPerPriceTypeData;

  releasesPerPriceType;
  releasesPerPriceTypeData;

  keepsPerPriceType;
  keepsPerPriceTypeData;

  deviceCategory;
  deviceCategoryData;

  addonsBalance;
  addonsBalanceData;

  verticalBarChartBaseConfig;
  pieChartBaseConfig: PieChartModel;
  advancedPieChartBaseConfig;

  totalRevenueBySaleData;
  totalRevenueBySaleConfig;

  constructor(private metricService: MetricService,
              private chartService: ChartsService) {
    this.pieChartBaseConfig = null;
  }

  ngOnInit(): void {
    this.setAddonsBalance();
    this.setDeviceCategoryMetric();
  }

  // Loads metric addons balance and set values
  private setAddonsBalance(): void {
    this.metricService.getAddonsBalance().subscribe(
      response => {
        this.addonsBalance = response;
        this.addonsBalanceData = this.chartService.parseAddonsPriceType(this.addonsBalance);
        this.advancedPieChartBaseConfig = {
          view: [],
          results: this.addonsBalanceData,
          animations: true,
          labels: true,
          legend: true,
          legendPosition: 'below',
          legendTitle: 'Balance',
          doughnut: false,
          gradient: false,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#B51725', '#C71A28', '#D91C2C', '#E32636', '#E53847', '#E84A58', '#EA5D68', '#EC6F79',
              '#EF818A', '#F1939B', '#F3A5AB']
          }
        };
      }
    );
  }

  // Loads metric devices and set values
  private setDeviceCategoryMetric(): void {
    this.metricService.getDeviceCategory().subscribe(
      deviceCategory => {
        this.deviceCategory = deviceCategory;
        this.deviceCategoryData = this.chartService.parseData(this.deviceCategory);
        this.pieChartBaseConfig = {
          view: [],
          results: this.deviceCategoryData,
          animations: true,
          labels: true,
          legend: false,
          legendPosition: 'below',
          legendTitle: 'Platform used',
          doughnut: false,
          gradient: false,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#B51725', '#C71A28', '#D91C2C', '#E32636', '#E53847', '#E84A58', '#EA5D68', '#EC6F79',
              '#EF818A', '#F1939B', '#F3A5AB']
          }
        };
        console.log(this.pieChartBaseConfig);
      }
    );
  }

  private setTotalRevenueBySale(): void {
    this.metricService.getTotalRevenueBySaleType().subscribe(
      totalRevenueBySale => {
        this.totalRevenueBySaleData = totalRevenueBySale;
        this.totalRevenueBySaleConfig = {
          view: [],
          results: this.chartService.parseData(this.totalRevenueBySaleData),
          animations: true,
          labels: true,
          legend: false,
          legendPosition: 'below',
          legendTitle: 'Total Revenue Gain',
          doughnut: false,
          gradient: false,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#B51725', '#C71A28', '#D91C2C', '#E32636', '#E53847', '#E84A58', '#EA5D68', '#EC6F79',
              '#EF818A', '#F1939B', '#F3A5AB']
          }
        };
      }
    );
  }
}
