import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapLegendComponent, MapViewComponent } from './components';
import { MapTopbarComponent } from './components';


@NgModule({
  declarations: [MapLegendComponent, MapTopbarComponent, MapViewComponent],
  imports: [
    CommonModule
  ],
    exports: [MapLegendComponent, MapTopbarComponent, MapViewComponent]
})
export class SharedModule { }
