import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalData, ModalComponent} from '../info-modal';

@Injectable()
export class AuthModalService {

  bsModalRef: BsModalRef;
  ifShowed = true;

  constructor(private modalService: BsModalService) { }

  private prepareObject(object): void {
    for (const i in object) {
      if (object[i] === undefined) {
        delete object[i];
      }
    }
  }

  public authModal(title, message, acceptBtnName?, acceptFunction?, closeBtnName?, closeFunction?, modalParentClass?): void {
    const initialState: object = {
      title,
      content: message,
      closeBtnName,
      closeFunction,
      acceptBtnName,
      acceptFunction
    };
    const config: ModalOptions = {
      animated: true,
      keyboard: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      initialState
    };
    modalParentClass = modalParentClass ? modalParentClass : '';
    modalParentClass += ' error-modal';
    this.prepareObject(config.initialState);
    this.bsModalRef = this.modalService.show(ModalComponent, config);
  }

  public changeFlag(): void {
    this.ifShowed = false;
  }

}
