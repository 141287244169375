import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InventoryManagementRoutingModule } from './inventory-management-routing.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SideInterfaceComponent } from './side-interface/side-interface.component';
import { InventoryLandingComponent } from './inventory-landing/inventory-landing.component';


@NgModule({
  declarations: [
    SideInterfaceComponent,
    InventoryLandingComponent
  ],
  imports: [
    CommonModule,
    InventoryManagementRoutingModule,
    TabsModule,
    FormsModule,
    SharedModule
  ]
})
export class InventoryManagementModule { }
