<ngx-loading-bar [height]="'15px'" [fixed]="false" [includeBar]="false" [diameter]="'100px'" [color]="this.loadingColorBar"></ngx-loading-bar>
<div class="page">
  <app-side-nav *ngIf="!router.url.includes('login')"></app-side-nav>
  <div class="page-detail">
    <div class="page-content">
      <app-header></app-header>
      <app-main-layout></app-main-layout>
    </div>
  </div>
</div>
