import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InventoryLandingComponent } from './inventory-landing/inventory-landing.component';

const routes: Routes = [
  {
    path: 'inventory',
    component: InventoryLandingComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryManagementRoutingModule { }
