import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvancedPieChartComponent } from './advanced-pie-chart';
import { HorizontalBarChartComponent } from './horizontal-bar-chart';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsService } from './charts.service';
import { PieChartComponent } from './pie-chart';
import { VerticalBarChartComponent } from './vertical-bar-chart';



@NgModule({
  declarations: [
    AdvancedPieChartComponent,
    HorizontalBarChartComponent,
    PieChartComponent,
    VerticalBarChartComponent
  ],
  imports: [
    CommonModule,
    NgxChartsModule
  ],
  exports: [
    AdvancedPieChartComponent,
    HorizontalBarChartComponent,
    PieChartComponent,
    VerticalBarChartComponent
  ],
  providers: [
    ChartsService
  ]
})
export class ChartsManagementModule { }
