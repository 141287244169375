import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { APP_CONFIG } from '../configuration/backoffice-configuration';
import { BackofficeConfigurationModel } from '../configuration/backoffice-configuration.model';
import { Router } from '@angular/router';
import { DjangoSessionAuthenticationService } from '../auth';
import { ActiveUserModel, LoggedMetricModel, UserLoadBarModel } from '../charts-management/models';
import { ChartsService } from '../charts-management/charts.service';
import { MetricService } from '../charts-management/metric.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {

  hideLoading: boolean;
  userConversion;
  userConversionData;
  userBar: UserLoadBarModel;
  pricetypeInventory: boolean;
  planInventoryAvailableData;
  onlineUsers: number;
  tableUsers: Array<ActiveUserModel>;
  deviceCategory;
  deviceCategoryData;

  dynamic = 70;
  intervalId;

  intervalList: Array<any>;

  horizontalChartBaseConfig;
  verticalBarChartBaseConfig;
  pieChartBaseConfig;

  userLoggedInData;
  userLoggedInConfig;

  constructor(@Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel,
              public router: Router, private chartService: ChartsService,
              private metricService: MetricService) {
    this.intervalList = [];
    this.userBar = {} as UserLoadBarModel;
    this.onlineUsers = 0;
    this.hideLoading = false;
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.setUserMetrics();
    this.intervalList[0] = setInterval(() => {
      this.setUserMetrics();
    }, 60000);
    // 60000 = 1 minutes
    // 1800000 = 30 minutes
    // this.intervalList[1] = setInterval(() => {
    //   this.setInventoryAvailableMetric();
    // }, 300000);
    this.setUsersLoggedInMetrics();
    // 300000 = 5 minutes


  }

  // Load user metrics and set values
  private setUserMetrics(): void {
    this.chartService.getUserConversionData().subscribe(
      loggedMetric => {
        this.setUserBar(loggedMetric);
        this.setUserStatistics(this.chartService.parseData(loggedMetric));
      }
    );
    this.chartService.getActiverUserSite().subscribe(
      activeUsers => {
        this.tableUsers = activeUsers;
        this.hideLoading = true;
        this.onlineUsers = Object.keys(activeUsers).length;
      }
    );
  }

  private setInventoryAvailableMetric(): void {
    this.metricService.getPriceTypeAvailability().subscribe(
      pricetypeInventory => {
        this.pricetypeInventory = true;
        this.planInventoryAvailableData = this.chartService.parseDataPriceType(pricetypeInventory);
        this.verticalBarChartBaseConfig = {
          view: [300, 0],
          results: this.planInventoryAvailableData,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#48090F', '#5A0C12', '#6C0E16', '#7E101A', '#90131D', '#A21521', '#B51725', '#C71A28', '#D91C2C', '#E32636',
              '#E53847', '#E84A58', '#EA5D68', '#EC6F79', '#EF818A', '#F1939B', '#F3A5AB']
          },
          animations: true,
          labels: false,
          gradient: false,
          legend: false,
          legendTitle: 'defautLegend',
          xAxisLabel: 'defaultX',
          yAxisLabel: 'defaultY',
          roundEdges: true
        };
      }
    );
  }

  private setUserStatistics(metrics): void {
    this.userConversionData = true;
    this.horizontalChartBaseConfig = {
      view: [0, 300],
      results: metrics,
      legend: false,
      legendTitle: 'default',
      xAxisLabel: 'Total Users',
      yAxisLabel: '',
      scheme: {
        // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
        domain: ['#B51725', '#C71A28', '#D91C2C', '#E32636', '#E53847', '#E84A58', '#EA5D68', '#EC6F79',
          '#EF818A', '#F1939B', '#F3A5AB']
      }
    };
  }

  private setUserBar(metrics: LoggedMetricModel): void {
    this.userBar.relocated_users = metrics.with_transaction_complete;
    this.userBar.total_users = metrics.total_users;
    this.userBar.porcentaje = (metrics.with_transaction_complete /  metrics.total_users * 100).toFixed(2);
  }

  private setUsersLoggedInMetrics(): void {
    this.metricService.getUsersLoggedIn().subscribe(
      userLoggedInData => {
        this.userLoggedInData = userLoggedInData;
        this.userLoggedInConfig = {
          view: [],
          results: this.chartService.parseLoggedInData(this.userLoggedInData),
          animations: true,
          labels: true,
          legend: false,
          legendPosition: 'below',
          legendTitle: 'Logged in vs not logged in',
          doughnut: false,
          gradient: false,
          scheme: {
            domain: [this.appConfig.colors.main, this.appConfig.colors.accent]
          }
        };
      }
    );
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  ngOnDestroy(): void {
    this.intervalList.forEach(interval => {
      clearInterval(interval);
    });
  }

}
