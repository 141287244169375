import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from './backoffice-configuration';
import { BackofficeConfigurationModel } from './backoffice-configuration.model';
import * as configurationJson from './configuration.json';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(@Inject(APP_CONFIG) private appConfig: BackofficeConfigurationModel) {
  }

  public init(): Promise<void> {
    const regex = /^(?:http|https):\/\/(?:local\.)?(?:private\.)?(?:demo-)?(?:dev-)?(.*)\.3ddigitalvenue\.com.*/gm;
    const client = regex.exec(location.href)[1];
    if (client && typeof client === 'string') {
      /* tslint:disable:no-string-literal */
      const configuration = configurationJson['default'][client];
      return new Promise<any>((success, reject) => {
        if (configuration) {
          for (const [key, value] of Object.entries(configuration)) {
            this.appConfig[key] = value;
          }
        }
        success(null);
        console.log(this.appConfig);
      });
    }
  }
}
