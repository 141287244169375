import { Component, Input, OnInit } from '@angular/core';
import { ChartConfigService } from '../chart-config.service';
import { PieChartModel } from './pie-chart.model';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  @Input() data;
  chartBaseConfig: PieChartModel;
  constructor(private chartConfigService: ChartConfigService) {}

  ngOnInit(): void {
    this.chartBaseConfig = this.chartConfigService.setPieChartConfig(this.data);
  }

}
