<div class="full flex mb-5">
  <div class="full flex spb">
    <div class="w40 flex j-center metrics-wrap f-column">
      <div class="metrics-title full">
        <h4>Platform</h4>
      </div>
      <div class="metrics-container" style="height: 350px;">
        <div class="metrics-content">
          <app-pie-chart style="width: 100%;" *ngIf="deviceCategory && pieChartBaseConfig" [data]="pieChartBaseConfig"></app-pie-chart>
        </div>
      </div>
    </div>
  </div>
</div>
