import { Component, Inject, OnInit } from '@angular/core';
import { AuthorizationData, BackofficeUser } from '../shared/models';
import { APP_CONFIG } from '../configuration/backoffice-configuration';
import { BackofficeConfigurationModel } from '../configuration/backoffice-configuration.model';
import { Router } from '@angular/router';
import { DjangoSessionAuthenticationService } from '../auth';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  loginData: AuthorizationData;
  user: BackofficeUser | boolean;
  errors: boolean;

  constructor(@Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel,
              private sessionService: DjangoSessionAuthenticationService,
              public router: Router) {
    this.loginData = {username: '', password: ''};
    this.errors = false;
  }

  ngOnInit(): void {
  }

  login(): void {
    this.sessionService.login(this.loginData).subscribe(
      () => { this.router.navigate(['/home'], { state: { ignoreLoadingBar: true } }); },
      (err) => {this.showError(err); }
    );
  }

  showError(error): void {
    console.error(error);
    this.errors = true;
  }

}
