import { Component, Inject, OnInit } from '@angular/core';
import { PlanModel, SectionDataModel, SeatDataModel, MessagingService } from '../../shared';
import { DVM_CONFIG } from '../../configuration/dvm.configuration';
import { DVMConfiguration } from '../../configuration/dvm-configuration.model';
import { FlowControlService, InventoryService } from '../services';
import { DvmService, NotificationService } from '../../shared';
import { saveAs } from 'file-saver';
import { APP_CONFIG } from '../../configuration/backoffice-configuration';
import { BackofficeConfigurationModel } from '../../configuration/backoffice-configuration.model';

@Component({
  selector: 'app-side-interface',
  templateUrl: './side-interface.component.html',
  styleUrls: ['./side-interface.component.scss']
})
export class SideInterfaceComponent implements OnInit {

  planSelected: number;
  sectionData: SectionDataModel;
  seatData: SeatDataModel;
  planList: Array<PlanModel>;
  seatStatus: string;
  seatSelectedLenght: number;
  errorOnSave = false;
  action: 'lock' | 'unlock';

  // Select Map
  selectedMap = 0;
  selectableMaps: object;
  selectableMapsArray: Array<object>;

  constructor(
    @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
    @Inject(APP_CONFIG) private appConfig: BackofficeConfigurationModel,
    private inventoryService: InventoryService,
    public flowservice: FlowControlService,
    private modalService: MessagingService,
    private notificationService: NotificationService, private dvmService: DvmService) {
      this.planSelected = 1;
      this.flowservice.planSelected = this.planSelected;
      this.flowservice.viewerMode = 'inventory';
      this.flowservice.colorsmode = 'inventory';
  }

  ngOnInit(): void {
    this.flowservice.getSectionData().subscribe(
      data => {
        this.sectionData = data;
      }
    );
    this.flowservice.getSeatData().subscribe(
      data => {
        this.seatData = data;
        if (this.seatData && this.flowservice.viewerMode === 'inventory') {
          this.seatStatus = this.getSeatStatus();
        }
      }
    );
    this.inventoryService.getSeatSelectedLength().subscribe(
      length => {
        this.seatSelectedLenght = length;
      }
    );
    this.inventoryService.errorOnSaveSubject.subscribe(
      error => {
        this.errorOnSave = error;
      }
    );
    this.inventoryService.getPlans().subscribe(
      planList => {
        this.planList = planList;
      }
    );
  }

  public getSeatStatus(): string {
    if (this.seatData.final_event_status.length === 0) {
      if (this.seatData.locked === true) {
        return 'locked';
      }
      return 'available';
    } else {
      switch (this.seatData.final_event_status[0].status) {
        case 'on_hold': {
          return 'on_hold';
        }
        case 'owned': {
          return 'owned';
        }
        case 'reserved': {
          return 'reserved';
        }
      }
    }
  }

  // Carga la disponibilidad total del estadio con los datos de los respectivos dueños
  onChangePlanSelectorInventory(newPlan?: number): void {
    this.flowservice.setSectionData(null);
    this.flowservice.setSeatData(null);
    this.flowservice.viewerMode = 'inventory';
    this.flowservice.colorsmode = 'inventory';
    const step = this.flowservice.flowStep;
    this.flowservice.planSelected = newPlan;
    if (step === 'blockmap') {
      this.inventoryService.getPlanInventory(newPlan).subscribe(
        availabilityBlockmap => {
          this.dvmService.viewer.setAvailable('section', availabilityBlockmap);
          this.flowservice.availabilityBlockmap = availabilityBlockmap;
        },
        error => {
          this.dvmService.viewer.setAvailable('section', []);
        }
      );
    }
    if (step === 'seatmap') {
      const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
      loadOptions.map_id = this.dvmService.viewer.getMapId();
      this.dvmService.loadMap(loadOptions);
      this.dvmService.viewer.setAvailable('seat', []);
      this.inventoryService.getSectionInventory(newPlan, this.dvmService.viewer.getMapId()).subscribe(
        availability => {
          this.flowservice.availabilitySeatmap = this.inventoryService.getSeatmapAvailability(availability);
          const filteredAvailability = this.inventoryService.createAvailabilityHashmap(availability);
          // tslint:disable-next-line: max-line-length
          this.dvmService.viewer.setAvailable('seat', Object.keys(filteredAvailability.available).concat(Object.keys(filteredAvailability.locked)));
          this.inventoryService.setSeatsGroup(filteredAvailability);
          const seatStyles = this.dvmService.setStyles(this.dvmService.viewer.getNodesByType('seat'));
          for (const seat of Object.keys(seatStyles)) {
            (this.dvmService.viewer as any).setNodeCustomStyles(seat, [seatStyles[seat]]);
          }
        },
        error => {
          this.dvmService.viewer.setAvailable('seat', []);
        }
      );
    }
  }

  // Carga la disponibilidad del plan, como la veria un cliente.
  onChangePlanSelectorCustomer(newPlan?: number): void {
    this.flowservice.setSectionData(null);
    this.flowservice.setSeatData(null);
    this.flowservice.colorsmode = 'pricing';
    this.flowservice.viewerMode = 'customer';
    const step = this.flowservice.flowStep;
    this.flowservice.planSelected = newPlan;
    if (step === 'blockmap') {
      this.inventoryService.getPlanInventory(newPlan).subscribe(
        availabilityBlockmap => {
          this.dvmService.viewer.setAvailable('section', []);
          const correctAvailability = this.excludeSectionWithoutSeats(availabilityBlockmap);
          this.dvmService.viewer.setAvailable('section', Object.keys(correctAvailability));
          this.flowservice.availabilityBlockmap = availabilityBlockmap;
        },
        error => {
          this.dvmService.viewer.setAvailable('section', []);
        }
      );
    }
    if (step === 'seatmap') {
      const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
      loadOptions.map_id = this.dvmService.viewer.getMapId();
      this.dvmService.loadMap(loadOptions);
      this.inventoryService.removeSeatsGroups();
      this.dvmService.viewer.addNodesToGroup(this.dvmService.viewer.getNodesByGroups('seat', 'unavailable'), 'customerView');
      this.dvmService.viewer.setAvailable('seat', []);
      this.inventoryService.getPlanAvailabilitySection(newPlan, this.dvmService.viewer.getMapId()).subscribe(
        availability => {
          this.flowservice.availabilitySeatmap = availability;
          this.dvmService.viewer.setAvailable('seat', Object.keys(availability));
          const seatStyles = this.dvmService.setStyles(this.dvmService.viewer.getNodesByType('seat'));
          for (const seat of Object.keys(seatStyles)) {
            (this.dvmService.viewer as any).setNodeCustomStyles(seat, [seatStyles[seat]]);
          }
        },
        error => {
          this.dvmService.viewer.setAvailable('seat', []);
        });
    }
  }

  onEnterSection(): void {
    this.flowservice.sectionSelected = this.sectionData.name;
    const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
    loadOptions.map_id = this.sectionData.name;
    loadOptions.venue_id = this.dvmService.viewer.getVenueId();
    this.dvmService.loadMap(loadOptions);
  }

  findPlanName(planid: any): string {
    const condition = (p) => p.id === parseInt(planid, 10);
    const i = this.planList.findIndex(condition);
    return this.planList[i].name;
  }

  getseatSection(seatID: string): string {
    return seatID.split('-')[0].split('_')[1];
  }

  getseatRow(seatID: string): string {
    return seatID.split('-')[1];
  }

  getseatSeat(seatID: string): string {
    return seatID.split('-')[2];
  }

  formatPrice(price: number): string {
    // tslint:disable-next-line:variable-name
    const _price = Math.abs(price);
    return _price.toLocaleString('en-US', { maximumFractionDigits: 2 });
  }

  excludeSectionWithoutSeats(availability: any): any {
    const list = {};
    for (const block of Object.keys(availability)) {
      // console.log(availability[block]);
      if (availability[block].seats !== 0) {
        list[block] = availability[block];
      }
    }
    return list;
  }

  onSave(): void {
    const seatList = []; // Listado vacio de las sillas para actualizar estado
    const flag = false;
    // tslint:disable-next-line: forin
    for (const seatId of Object.keys(this.flowservice.seatHash)) {
      seatList.push(seatId);
    }
    if (this.action === 'lock') {
      this.onSaveLock(seatList);
    } else if (this.action === 'unlock') {
      this.onSaveUnlock(seatList);
    }
  }

  private filterLocked(seat: SeatDataModel): boolean {
    // if status length it's > 0 means that have any status
    if (seat.final_event_status.length !== 0) {
      // it's locked
      return true;
    } else {
      // it's unlocked
      return false;
    }
  }

  private filterUnlocked(seat: SeatDataModel): boolean {
    // if status length it's 0 means that does not have any status
    // and by default it's available
    if (seat.final_event_status.length === 0) {
      // it's available
      return true;
    } else {
      // it's not available
      return false;
    }
  }

  private onSaveLock(seatList: any): void {
    this.inventoryService.lockSeat(seatList, this.planSelected).subscribe(
      data => {
        this.notificationService.showSuccess('Seat status saved');
        this.dvmService.viewer.removeNodesFromGroup(seatList, 'available');
        this.dvmService.viewer.addNodesToGroup(seatList, 'locked');
        // Añadimos el estado locked al asiento. TODO Formatear objeto correctamente
        // tslint:disable-next-line: forin
        for (const seatId of Object.keys(this.flowservice.seatHash)) {
          const seatObject = this.flowservice.availabilitySeatmap[seatId];
          seatObject.locked = true;
        }

      },
      err => {
        this.notificationService.showError('Could not save status');
        console.log(err);
      }
    );
  }

  private onSaveUnlock(seatList: any): void {
    this.inventoryService.unlockSeats(seatList, this.planSelected).subscribe(
      data => {
        this.notificationService.showSuccess('Seat status saved');
        this.dvmService.viewer.removeNodesFromGroup(seatList, 'locked');
        this.dvmService.viewer.addNodesToGroup(seatList, 'available');
        // Borramos cualquier rastro del anterior estado
        for (const seatId of Object.keys(this.flowservice.seatHash)) {
          const seatObject = this.flowservice.availabilitySeatmap[seatId];
          seatObject.locked = false;
        }
      },
      err => {
        this.notificationService.showError('Could not save status');
        console.log(err);
      }
    );
  }

  get getSeatSelected(): number {
    return this.seatSelectedLenght;
  }

  public onExport(): void {
    const title = 'Export';
    const message = 'Do you really want to export the data?';
    const acceptBtnName = 'Accept';
    const closeBtnName = 'Close';
    this.modalService.info(title, message, acceptBtnName, () => {
      this.exportCSV();
    }, closeBtnName);
  }

  exportCSV(): void {
    this.inventoryService.getPlanCSV(this.planSelected).subscribe(
      data => {
        const planName = this.findPlanName(this.planSelected).replace(/\s/g, '_');
        saveAs(data, `plan_${planName}.csv`);
      }
    );
  }

  public onExportSection(): void {
    const title = 'Export';
    const message = 'Do you really want to export the data?';
    const acceptBtnName = 'Accept';
    const closeBtnName = 'Close';
    this.modalService.info(title, message, acceptBtnName, () => {
      this.exportSectionCSV();
    }, closeBtnName);
  }

  private exportSectionCSV(): void {
    const section = this.dvmService.viewer.getMapId();
    this.inventoryService.getPlanFromSectionCSV(this.planSelected, section).subscribe(
      data => {
        const planName = this.findPlanName(this.planSelected).replace(/\s/g, '_');
        saveAs(data, `plan_${planName}.csv`);
      }
    );
  }

  selectChange(newValue): void {
    this.flowservice.setSeatData(null);
    this.flowservice.setSectionData(null);
    this.selectedMap = newValue;
    // tslint:disable:no-string-literal
    this.dvmService.changeMapConfiguration(this.selectableMapsArray[newValue]['venue']);
  }

  goBack(): void {
    const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
    loadOptions.venue_id = this.dvmService.viewer.getVenueId();
    this.flowservice.flowStep = 'blockmap';
    this.flowservice.setSectionData(null);
    this.flowservice.setSeatData(null);
    this.flowservice.seatHash = {};
    this.flowservice.sectionSelected = null;
    this.inventoryService.seatsSelectedLengh.next(Object.keys(this.flowservice.seatHash).length);
    this.dvmService.loadMap(loadOptions);
  }

}
