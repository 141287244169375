import { Component, Inject, OnInit } from '@angular/core';
import { BackofficeConfigurationModel } from './configuration/backoffice-configuration.model';
import { Router } from '@angular/router';
import { APP_CONFIG } from './configuration/backoffice-configuration';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'smp-mlb-standard-angular-backoffice';
  loadingColorBar: string;

  constructor(@Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel,
              public router: Router) {
    this.loadingColorBar = this.appConfig.colors.main;
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    document.documentElement.style.setProperty('--main-color', this.appConfig.colors.main);
    document.documentElement.style.setProperty('--accent-color', this.appConfig.colors.accent);
    document.documentElement.style.setProperty('--header-color', this.appConfig.colors.header);
  }
}
