import { Inject, Injectable } from '@angular/core';
import { DvmService } from '../../shared';

@Injectable()
export class GeneralHandler {
  constructor(@Inject('event') private event: any,
              protected dvmService: DvmService) {
    this.dvmService.subscribeHandler(event, this.handle.bind(this));
  }

  protected handle(...args): void {}
}
