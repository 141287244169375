import { BackofficeConfigurationModel } from './backoffice-configuration.model';
import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<BackofficeConfigurationModel>('BackofficeConfiguration');
export const ApplicationConfiguration: BackofficeConfigurationModel = {
  id: '',
  team: '',
  tdc: true,
  topbar: {
    logoUrl: ''
  },
  colors: {
    main: '',
    accent: '',
    header: ''
  },
  generalAdmissionAreas: [],
  rules: {},
  dvmConfig: {
    venue_id: '',
    map_id: '',
    version: 'development'
  },
  fields: {}
};
