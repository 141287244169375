<div id='topbar' class='col-xs-12 no-padding'>
  <button id='back-btn' class='pull-left mt-e icon-container' type='button' *ngIf="isStep2" (click)="goBack()">
    <!-- <i class="fa fa-angle-left" aria-hidden="true"></i> -->
    <!-- <div class="icon arrow-down-line" (click)="logout()" id="normal-stairs-btn"></div> -->
    <div class="icon arrow-left-line2"></div>
  </button>
  <div class='info-container col-md-12 col-xs-12'>
    <div *ngIf='isStep2' class="info-text-container">
      <span class="info-text">Section </span>
      <span class="info-text">{{currentSection}}</span>
    </div>
  </div>
</div>

